import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function GridAVISOS({ avisos, id }) {

  const navigate = useNavigate();

  // Estado local para manejar avisoInicial
  const [avisoInicial, setAvisoInicial] = useState(() => {
    const storedValue = localStorage.getItem('avisoInicial');
    return storedValue === "true" || storedValue === null;
  });

  useEffect(() => {
    if (!avisoInicial) {
      // Actualizar localStorage cuando avisoInicial es false
      localStorage.setItem('avisoInicial', "false");
    }
  }, [avisoInicial]);

  // Contenido del aviso destacado con botón
  const renderAvisoDestacado = () => (
    <div className="aviso-actos">
      <div>
        <p>
          Aquí se mostrarán comunicados que tu Comparsa o que la Unión de Festejos 
          quiera comunicar:<br/>
          <br/>- Suspensión de actos.
          <br/>- Retrasos de actos.
          <br/>- Aplazamiento de actos.
          <br/>- Fin de plazos para pagos o altas...
          <br/>
          <br/>Y otros aspectos importantes.
        </p>
      </div>
      {/* Botón para no mostrar más el aviso */}
      <button
        className="boton"
        onClick={() => {
          setAvisoInicial(false); // Ocultar el aviso destacado
          if (avisos.length < 1) {
            navigate(-1); // Navegar a la página anterior si no hay avisos
          }
        }}
        style={{
          backgroundColor: 'white',
          color: '#ff0000cc',
        }}
      >
        Aceptar
      </button>
    </div>
  );

  return (
    <div className="home2">
      <div className="home-vent vent">
        <div className="title-vent">
          <b>AVISOS IMPORTANTES</b>
          <br />
        </div>
        <br />
        {avisos.length > 0 ? (
          <>
            {/* Mostrar aviso destacado si avisoInicial es true */}
            {avisoInicial && renderAvisoDestacado()}
            {/* Lista de avisos */}
            {avisos.map((aviso, index) => (
              <div className="aviso-actos" key={index}>
                {/* Imagen a la izquierda */}
                <img
                  src={`/comparsas/c_${aviso.idPadre}.png`}
                  alt={`Comparsa ${aviso.nombrePadre}`}
                  className="aviso-icon"
                />
                <p>
                  Aviso para {aviso.idPadre !== 0 ? `${aviso.nombrePadre}` : "TODAS las Comparsas"}
                </p>
                <p><b>{aviso.nombre}</b></p>
                <p>{aviso.descripcion}</p>
              </div>
            ))}
          </>
        ) : (
          renderAvisoDestacado()
        )}
      </div>
    </div>
  );
}

export default GridAVISOS;
