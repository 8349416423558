import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppProvider } from './context/AppContext';
import { CookiesProvider } from './context/CookiesContext';  // Importar el proveedor de cookies
import { ComparsaProvider } from './context/ComparsaContext';  // También asegúrate de que ComparsaContext esté envuelto


import 'leaflet/dist/leaflet.css';

import './styles/main.css';
import './styles/splitsOpciones.css';
import './styles/grids.css';
import './styles/ficha.css';
import Home from './pages/Home';

import HeaderParaNav from './components/menu/CompMenuHeaderParaNav';

import PageListaActos from './pages/PageListaActos';
import PageListaOrdenes from './pages/PageListaOrdenes';
import PageListaCargos from './pages/PageListaCargos';
import PageListaComparsas from './pages/PageListaComparsas';
import PageListaLugares from './pages/PageListaLugares';
import PageListaComercioTipos from './pages/PageListaComercioTipos';
import PageListaComercios from './pages/PageListaComercios';
import PageEstAjustes from './pages/PageEstAjustes';
import PageEstAcercade from './pages/PageEstAcercade';
import PageAvisoLegal from './pages/PageAvisoLegal';
import PageListaAvisos from './pages/PageListaAvisos';

import PageFActo from './pages/PageFActo';
import PageFFila from './pages/PageFFila';
import PageFOrden from './pages/PageFOrden';
import PageFComparsa from './pages/PageFComparsa';
import PageFLugar from './pages/PageFLugar';
import PageFComercio from './pages/PageFComercio';
import PageFCargo from './pages/PageFCargo';
import PageMaps from './pages/PageMaps';

import PageFormaListaFilasCuadros from './pages/PageFormaListaFilasCuadros';



// Componente principal con control de navegación
function App() {

  async function gestionarCacheImagenes() {
    const versionActual = await obtenerVersionActual();
    const versionLocal = localStorage.getItem('version_imagenes') || 0;
  
    if (parseInt(versionActual, 10) > parseInt(versionLocal, 10)) {
      if ('caches' in window) {
        const cacheNames = await caches.keys();
        console.log('Cache Names:', cacheNames);
  
        for (const cacheName of cacheNames) {
          const cache = await caches.open(cacheName);
          const requests = await cache.keys();
          console.log(`Requests in cache ${cacheName}:`, requests.map(r => r.url));
  
          for (const request of requests) {
            if (request.url.match(/\.(jpg|jpeg|png|gif)$/)) {
              console.log(`Deleting: ${request.url}`);
              await cache.delete(request);
            }
          }
        }
      }
      localStorage.setItem('version_imagenes', versionActual);
      console.log(`Cache de imágenes borrada. Versión actualizada a ${versionActual}.`);
    } else {
      console.log(`Cache de imágenes ya está sincronizada. Versión: ${versionActual}.`);
    }
  }
  

    async function obtenerVersionActual() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/version-imagenes`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data.version;
      } catch (error) {
        console.error('Error al obtener la versión actual:', error);
        return 0; // Valor por defecto en caso de error
      }
    }
    
  
    useEffect(() => {
      gestionarCacheImagenes();
    }, []);
  
  return (
    <CookiesProvider>
    <ComparsaProvider>
    <AppProvider>
      <Router>
        <Routes>
          {/* RUTAS ESTÁTICAS */}
          <Route path="/" element={<Home />} />


          {/*  ACTOS / ACTO / MAP  */}
          <Route path="/actos" element={<PageListaActos />} />
          <Route path="/acto/:idActo" element={<PageFActo />} />
          {/* <Route path="/acto/:idActo/maps" element={<PageMaps />} /> */}


          {/*  ORDENES / ORDEN  */}
          <Route path="/ordenes" element={<PageListaOrdenes />} />
          <Route path="/orden/:idOrden" element={<PageFOrden />} />


          {/*  COMPARSAS / COMPARSAS / COMPARSA / MAP  */}
          {/*            /           /          / CARGOS   / CARGO  */}
          {/*            /           / CARGOS   / CARGO  */}
          {/*            /           / FILA     / MAP  */}
          <Route path="/comparsas" element={<PageListaComparsas />} />
          <Route path="/comparsa/:idComparsa" element={<PageFormaListaFilasCuadros />} />
          <Route path="/comparsa/:idComparsa/info" element={<PageFComparsa />} />
          {/* <Route path="maps" element={<PageMaps />} /> */}
          <Route path="/comparsa/:idComparsa/cargos" element={<PageListaCargos />} />
          <Route path="/comparsa/:idComparsa/cargos/:anyo" element={<PageFCargo />} />
          {/* <Route path="/comparsa/:idComparsa/cargos/:idCargo" element={<PageFCargo />} /> */}
          {/* <Route path="idImagen" element={<PageFImagen />} /> */}
          <Route path="/comparsa/:idComparsa/fila/:idFila" element={<PageFFila />} />
          {/* <Route path=":idImagen" element={<PageFImagen />} /> */}
          {/* <Route path="maps" element={<PageMaps />} /> */}


          {/*  LUGARES / LUGAR / MAP  */}
          <Route path="/lugares-interes" element={<PageListaLugares />} />
          <Route path="/lugares-interes/:idLugar" element={<PageFLugar />} />
          {/* <Route path="idImagen" element={<PageFImagen />} /> */}
          {/* <Route path="maps" element={<PageMaps />} /> */}


          {/*  COMERCIO-TIPOS / COMERCIOS / COMERCIO / MAP  */}                   {/* Solo cuando haga falta PageTiposComercios */} 
          <Route path="/comercio-tipos" element={<PageListaComercioTipos />} />
          <Route path="/comercios/:idTipo" element={<PageListaComercios />} />
          <Route path="/comercio/:idComercio" element={<PageFComercio />} />
          {/* <Route path="idImagen" element={<PageFImagen />} /> */}
          {/* <Route path="maps" element={<PageMaps />} /> */}

          {/*  COMERCIOS / COMERCIO / MAP  */}                                    {/* Solo cuando hayan pocos y no haga falta PageTiposComercios */}
          <Route path="/comercios" element={<PageListaComercios />} />
{/*              <Route path=":idComercio" element={<PageFComercio />}>
              <Route path="idImagen" element={<PageFImagen />} />*/}
          
          {/*  MAPS  */}
          <Route path="/map" element={<PageMaps />} /> 


          {/*  BANNER  */}
          {/*  COMERCIO / MAP  */}


          {/*  miniBANNER  */}
          {/*  COMERCIO / MAP  */}


          <Route path="/ajustes" element={<PageEstAjustes />} />
          <Route path="/acerca-de" element={<PageEstAcercade />} />
          <Route path="/aviso-legal" element={<PageAvisoLegal />} />

          <Route path="/avisos/:idComparsa" element={<PageListaAvisos />} />

          {/* Ruta 404: Coincide con cualquier ruta que no esté definida */}
          <Route path="*" element={<h1><br/><br/><br/><br/>404 - Página No Encontrada</h1>} />
        </Routes>

        <HeaderParaNav />

      </Router>
    </AppProvider>
    </ComparsaProvider>
    </CookiesProvider>
  );
}

export default App;
