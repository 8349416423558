import React from 'react';
import logo from '../../assets/logo1024t.png';

function FichaCargo({ items, type, rPadre, rHijo}) {

  if (items.length === 0) {
    return <div>No hay Cargos Festeros para mostrar.</div>;
  }

  const resaltarPalabras = [
    "capitán:",
    "capitanes:",
    "abanderada:",
    "abanderadas:",
    "rodela:",
    "rodelas:",
    "embajador moro:",
    "embajador cristiano:",
  ];
  
  const regexPalabras = new RegExp(`(${resaltarPalabras.join('|')})`, 'gi');

  return (
    <div className="home-ficha">

      {/* PRIMER DIV: NombrePadre alineado a la derecha en un contenedor verde */}
      <div className="nombre-padre">
        <p>{items.nombrePadre}</p>
      </div>


      {/* IMAGEN FLOTANTE: Icono en la esquina superior izquierda */}
      <div className="ico-foto-flotante">
        <img src={`${rPadre}${items.icoPadre}`} alt="Icono principal" />
      </div>


      {/* SEGUNDO DIV: Nombre principal centrado en negrita */}
      <div className="nombre-principal">
        <p>{items.anyo ? items.anyo : ''}</p>
      </div>


      {/* DIV CONTENEDOR: Todo lo que viene después con padding */}
      <div className="contenido">

        {/* IMAGEN PRINCIPAL: ajustada al ancho completo */}
        {items.fotoCargo && (
          <div className="foto-capitania">
            <img
              src={`${rHijo}${items.fotoCargo}`}
              alt={items.fotoCargo}
            />
          </div>
        )}


        {/* CAPITANIA DESCRIPCION */}
        <div className="capitania">
          {items.fotoEmbaj === "" ? (
            <strong style={{ color: 'green', fontSize: '25px'}}>Capitanía</strong>
          ) : (
            <strong style={{ color: 'green', fontSize: '25px' }}>Capitanía y Embajador</strong>
          )}   
          <p
            dangerouslySetInnerHTML={{
              __html: items.capitania
                ? items.capitania
                    .replace(/\r?\n/g, '<br />') // Saltos de línea
                    .replace(regexPalabras, '<strong><i>$&</i></strong>') // Resaltar palabras
                : "", // Si capitania es null o vacío
            }}
          ></p>
        </div>


        {/* IMAGEN SECUNDARIA: ajustada al ancho completo */}
        {items.fotoEmbaj && (
          <div className="foto-capitania">
            <img
              src={`${rHijo}${items.fotoEmbaj}`}
              alt={items.fotoEmbaj}
            />
          </div>
        )}


        {/* LOGO APP */}
        {/* EMAIL APP */}
        <div className="correo">
        <img style={{ maxWidth: "60px"}} src={logo} alt="Logo" />
          <div>Para modificar algún dato:</div>
          <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
            {process.env.REACT_APP_EMAIL}
          </a>
        </div>

      </div>
    </div>
  );
}

export default FichaCargo;

